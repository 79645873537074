import { ReactElement, memo } from 'react';
import * as S from './WhyBookWithTripshock.styles';
import { ItemImage } from './ItemImage';
import { Container } from '@/common/ui/containers/Container/Container';

export const WhyBookWithTripshock = (): ReactElement => {
    return (
        <S.Wrapper data-test-id="Why_book">
            <Container>
                <S.Container>
                    <S.Title>Why Book with TripShock?</S.Title>
                    <S.Grid>
                        <ItemImage>
                            <S.lowPrices />
                            <S.WhyBookTitle>Low prices</S.WhyBookTitle>
                            <S.WhyBookSubtitle>Guaranteed</S.WhyBookSubtitle>
                        </ItemImage>
                        <ItemImage>
                            <S.localSupport />
                            <S.WhyBookTitle>Local support</S.WhyBookTitle>
                            <S.WhyBookSubtitle>With a live agent</S.WhyBookSubtitle>
                        </ItemImage>
                        <ItemImage>
                            <S.verified />
                            <S.WhyBookTitle>Verified</S.WhyBookTitle>
                            <S.WhyBookSubtitle>Traveler reviews</S.WhyBookSubtitle>
                        </ItemImage>
                        <ItemImage>
                            <S.secure />
                            <S.WhyBookTitle>Secure</S.WhyBookTitle>
                            <S.WhyBookSubtitle>Payment processing</S.WhyBookSubtitle>
                        </ItemImage>
                    </S.Grid>
                </S.Container>
            </Container>
        </S.Wrapper>
    );
};

export default memo(WhyBookWithTripshock);
