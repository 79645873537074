export const ArrowButton = () => {
    return (
        <div className="ArrowButton">
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.1704 8.2053L8.78601 0.922372C8.56882 0.708168 8.20683 0.708168 7.98964 0.922372C7.77246 1.13658 7.77246 1.49358 7.98964 1.70779L14.4329 8.0625H1.32917C1.03959 8.0625 0.75 8.27671 0.75 8.63371C0.75 8.99072 0.967189 9.20492 1.32917 9.20492H14.5053L8.20683 15.4168C7.98964 15.631 7.98964 15.988 8.20683 16.2023C8.27923 16.2737 8.42402 16.3451 8.56882 16.3451C8.71361 16.3451 8.8584 16.2737 8.9308 16.2023L16.1704 9.06212C16.3876 8.70511 16.3876 8.34811 16.1704 8.2053Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
