import styled, { css } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { LowPrices } from '@/shared/images/icons/LowPrices';
import { LocalSupport } from '@/shared/images/icons/LocalSupport';
import { Verified } from '@/shared/images/icons/Verified';
import { Secure } from '@/shared/images/icons/Secure';
import { cssVars } from '@/styles/Themes/cssVars';

export const Wrapper = styled.div`
    padding: 22px 0 25px;

    ${Screen.ipad} {
        padding: 27px 0 30px;
    }
`;

export const Container = styled.div`
    background-color: ${cssVars.bg_white_blue};

    ${Screen.desktop} {
        padding: 0;
        margin: 0 auto;
    }
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2}
    margin: 0 0 24px;

    ${Screen.ipad} {
        margin: 0 0 21px;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-gap: 12px;
    ${Screen.ipad} {
        grid-gap: 30px;
    }
    ${Screen.desktop} {
        grid-template: none / 1fr 1fr 1fr 1fr;
        grid-gap: 24px;
    }
`;

const _svg = css`
    ${Screen.ipad} {
        width: 100px;
        height: 100px;
    }
    ${Screen.desktop} {
        width: 85px;
        height: 85px;
    }
`;

export const lowPrices = styled(LowPrices)`
    ${_svg}
`;

export const localSupport = styled(LocalSupport)`
    ${_svg}
`;

export const verified = styled(Verified)`
    ${_svg}
`;

export const secure = styled(Secure)`
    ${_svg}
`;

export const WhyBookTitle = styled.div`
    ${Text.Full.large.semibold};
    color: ${cssVars.fg_gray1_white};
    padding-top: 20px;
`;

export const WhyBookSubtitle = styled.div`
    ${Text.Full.medium.regular};
    color: ${cssVars.fg_gray2_gray3};
`;
