import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';
import { Palette } from '@/styles/Themes/Palette';
import { VectorGreen } from '@/shared/images/icons/VectorGreen';
import { Transition } from '@/styles/Themes/KeyFrames';

export const Container = styled.a`
    width: 100%;
    position: relative;
    display: block;
    border-radius: ${radius.small};
    background-color: ${cssVars.bg_white_dark3};
    box-shadow: 0px 4px 24px ${cssVars.shadow_normal};
    padding: 12px 12px 18px;

    &:hover {
        box-shadow: ${cssVars.shadow_card_hover};
        ${Transition.normal};
    }
`;

export const ImgWrap = styled.div`
    height: 188px;
    position: relative;
    display: flex;
    border-radius: ${radius.small};
    overflow: hidden;

    ${Screen.ipad} {
        height: 324px;
    }

    ${Screen.desktop} {
        height: 228px;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;

export const Date = styled.span`
    background-color: ${Palette.white.normal};
    color: ${Palette.gray.gray1};
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 12px 0 0 12px;
    padding: 0 22px;
    border-radius: ${radius.button};

    ${Text.Full.medium.semibold}
`;

export const Title = styled.span`
    color: ${cssVars.fg_gray1_white};
    width: 85%;
    display: block;
    margin: 16px 0 34px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Text.Full.medium.semibold};

    ${Screen.ipad} {
        margin: 18px 0 30px 10px;
    }
`;

export const Location = styled.span`
    display: flex;
    align-items: center;
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.normal.regular}
    position: absolute;
    bottom: 18px;
    left: 12px;
`;

export const LocationImg = styled(VectorGreen)`
    width: 13px;
    height: 15px;
    margin: 0 6px 0 10px;
`;
