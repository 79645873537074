import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { LeftArrow, RightArrow } from '@/common/ui';

export const Wrapper = styled.div`
    width: 100%;
`;

export const Block = styled.div`
    max-width: 100%;
    position: relative;
    ${Screen.desktop} {
        padding: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2};
`;

export const CarouselWraper = styled.div`
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: calc(100% + 48px);
    padding: 0 24px;
    transform: translateX(-24px);

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to left, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            0deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to right, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            180deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
`;

export const Gallery = styled.div`
    display: flex;
    user-select: none;

    overflow-x: auto;
    scroll-behavior: smooth;
    transition: 0.5s ease 0s;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        display: none;
    }

    width: calc(100% + 48px);
    padding: 10px 32px;
    transform: translateX(-24px);
    ${Screen.ipad} {
        padding: 24px;
    }
`;

export const InnerContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    width: auto;
    transition: 0.5s ease 0s;
    grid-gap: 24px;

    ${Screen.ipad} {
        grid-gap: 24px;
    }
`;

export const CenterLeftArrow = styled(LeftArrow)`
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    ${Screen.ipad} {
        left: 5px;
    }
`;

export const CenterRightArrow = styled(RightArrow)`
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    ${Screen.ipad} {
        right: 5px;
    }
`;
