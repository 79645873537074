export const VectorGreen = (props: { className?: string }) => {
    return (
        <div className={`Vector ${props.className}`}>
            <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.38335 8.08959C3.90935 8.08959 2.69169 6.87164 2.69169 5.39728C2.69169 3.92292 3.90935 2.70498 5.38335 2.70498C6.85736 2.70498 8.07502 3.92292 8.07502 5.39728C8.07502 6.87164 6.85736 8.08959 5.38335 8.08959ZM5.38335 3.73062C4.42204 3.73062 3.653 4.49985 3.653 5.46139C3.653 6.42292 4.42204 7.19215 5.38335 7.19215C6.34466 7.19215 7.11371 6.42292 7.11371 5.46139C7.11371 4.49985 6.34466 3.73062 5.38335 3.73062Z"
                    fill="#0DD1C5"
                />
                <path
                    d="M5.38333 12.8333C5.31925 12.8333 5.25516 12.8333 5.19107 12.7691C4.99881 12.705 0 10.3974 0 5.71787C0 4.30761 0.576786 2.89735 1.60218 1.93582C2.56349 0.910175 3.97341 0.333252 5.38333 0.333252C6.79325 0.333252 8.20317 0.910175 9.16448 1.93582C10.1899 2.96146 10.7667 4.30761 10.7667 5.71787C10.7667 10.5256 5.76786 12.705 5.5756 12.7691C5.51151 12.8333 5.44742 12.8333 5.38333 12.8333ZM5.38333 1.29479C4.22976 1.29479 3.07619 1.74351 2.24306 2.57684C1.40992 3.41017 0.96131 4.49992 0.96131 5.71787C0.96131 9.30761 4.48611 11.3589 5.38333 11.8076C6.28056 11.3589 9.80536 9.37171 9.80536 5.71787C9.80536 4.56402 9.35675 3.41017 8.52361 2.57684C7.69048 1.74351 6.5369 1.29479 5.38333 1.29479Z"
                    fill="#0DD1C5"
                />
                <path
                    d="M5.38333 12.8333C5.31925 12.8333 5.25516 12.8333 5.19107 12.7691C4.99881 12.705 0 10.3974 0 5.71787C0 4.30761 0.576786 2.89735 1.60218 1.93582C2.56349 0.910175 3.97341 0.333252 5.38333 0.333252C6.79325 0.333252 8.20317 0.910175 9.16448 1.93582C10.1899 2.96146 10.7667 4.30761 10.7667 5.71787C10.7667 10.5256 5.76786 12.705 5.5756 12.7691C5.51151 12.8333 5.44742 12.8333 5.38333 12.8333ZM5.38333 1.29479C4.22976 1.29479 3.07619 1.74351 2.24306 2.57684C1.40992 3.41017 0.96131 4.49992 0.96131 5.71787C0.96131 9.30761 4.48611 11.3589 5.38333 11.8076C6.28056 11.3589 9.80536 9.37171 9.80536 5.71787C9.80536 4.56402 9.35675 3.41017 8.52361 2.57684C7.69048 1.74351 6.5369 1.29479 5.38333 1.29479Z"
                    fill="#0DD1C5"
                />
            </svg>
        </div>
    );
};
