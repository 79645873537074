export const RecentSearchedIcon = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.2887 8.29667C20.7353 7.18976 19.8129 6.08286 18.7059 5.34492C17.599 4.51474 16.3999 3.96129 15.1085 3.68456C13.8171 3.40783 12.4335 3.40783 11.1421 3.7768C9.8507 4.05353 8.65155 4.69922 7.63688 5.5294C6.62222 6.35958 5.6998 7.46649 5.14634 8.66564C4.77737 9.49582 4.50065 10.4182 4.31616 11.4329L2.6558 9.6803C2.37908 9.40358 1.91787 9.40358 1.64114 9.6803C1.36441 9.95703 1.36441 10.4182 1.64114 10.695L4.50065 13.5545C4.59289 13.6467 4.77737 13.739 4.96186 13.739C5.14634 13.739 5.33083 13.6467 5.42307 13.5545L8.28258 10.695C8.55931 10.4182 8.55931 9.95703 8.28258 9.6803C8.00585 9.40358 7.54464 9.40358 7.26791 9.6803L5.79204 11.2484C5.88428 10.6027 6.06877 9.86479 6.43773 9.21909C6.89895 8.20443 7.63688 7.282 8.46706 6.54407C9.29724 5.80613 10.4041 5.34492 11.5111 5.06819C12.618 4.79147 13.7249 4.79147 14.8318 4.97595C15.9387 5.16043 16.9533 5.71389 17.8758 6.35958C18.7982 7.00528 19.5361 7.9277 20.0896 8.94236C20.643 9.95703 20.9198 11.0639 20.9198 12.1708C20.9198 13.2777 20.7353 14.4769 20.2741 15.4916C20.1818 15.676 20.1818 15.7683 20.0896 15.9528C19.4439 17.2442 18.4292 18.3511 17.2301 19.089C16.0309 19.8269 14.555 20.1959 13.1714 20.1959C11.6955 20.1959 10.3119 19.7347 9.11276 18.9045C7.91361 18.0743 6.99119 16.9674 6.34549 15.676C6.16101 15.3071 5.79204 15.2148 5.42307 15.3071C5.0541 15.4916 4.96186 15.8605 5.0541 16.2295C5.79204 17.7976 6.89895 19.089 8.28258 20.0114C9.66621 20.9339 11.3266 21.4873 13.0792 21.4873C13.1714 21.4873 13.1714 21.4873 13.2637 21.4873C14.924 21.4873 16.4921 21.0261 17.968 20.1959C19.4439 19.2735 20.643 18.0743 21.381 16.5062C21.4732 16.3217 21.5655 16.1373 21.6577 15.9528C22.2112 14.7536 22.3956 13.37 22.3956 12.0786C22.3034 10.8795 21.9344 9.49582 21.2887 8.29667Z"
                fill="currentColor"
            />
            <path
                d="M10.8652 16.1375L13.817 13.1857C13.9092 13.0935 14.0015 12.909 14.0015 12.7245V7.00552C14.0015 6.63655 13.7247 6.26758 13.2635 6.26758C12.8023 6.26758 12.5256 6.5443 12.5256 7.00552V12.3556L9.75833 15.1228C9.4816 15.3996 9.4816 15.8608 9.75833 16.1375C9.85057 16.2297 10.0351 16.322 10.2195 16.322C10.404 16.322 10.6807 16.2297 10.8652 16.1375Z"
                fill="currentColor"
            />
        </svg>
    );
};
