import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from 'src/styles/Themes/Screen';
import { Button } from '@/common/ui';

export const Container = styled.div`
    margin: 30px 18px;
    max-width: 1124px;
    ${Screen.ipad} {
        margin: 40px 30px;
    }
    ${Screen.desktop} {
        padding: 0 30px;
        margin: 50px auto;
    }
`;

export const Title = styled.h3`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2}
`;

export const GridContainer = styled.div`
    display: grid;
    grid-gap: 12px;
    margin: 24px 0;

    ${Screen.desktop} {
        grid-auto-flow: column;
        grid-gap: 24px;
    }
`;

export const Grid = styled.div<{ reverse?: boolean; half?: boolean }>`
    display: grid;

    grid-gap: 12px;

    ${Screen.desktop} {
        grid-gap: 24px;
        ${({ half }) => (half ? 'width: 50%;' : '')}
    }

    * {
        max-height: 290px;
    }

    ${({ reverse }) =>
        reverse
            ? css`
                  grid-template: 180px 290px / 1fr 1fr;

                  ${Screen.desktop} {
                      grid-template: 200px 250px / 1fr 1fr;
                  }

                  *:last-child {
                      grid-column-start: 1;
                      grid-column-end: 3;
                  }
              `
            : css`
                  grid-template: 290px 180px / 1fr 1fr;

                  ${Screen.desktop} {
                      grid-template: 250px 200px / 1fr 1fr;
                  }

                  *:first-child {
                      grid-column-start: 1;
                      grid-column-end: 3;
                  }
              `}
`;

export const MoreButton = styled(Button)`
    max-width: 274px;
    margin: 0 auto;
`;
