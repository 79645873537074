import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { ArrowButton } from '@/shared/images/icons/ArrowButton';
import Subscribe_thanks_desktop from '@/shared/images/Images/Subscribe_thanks_desktop.png';
import Subscribe_thanks_desktop_dark from '@/shared/images/Images/Subscribe_thanks_desktop_dark.png';
import Subscribe_thanks_mobile from '@/shared/images/Images/Subscribe_thanks_mobile.png';
import Subscribe_thanks_mobile_dark from '@/shared/images/Images/Subscribe_thanks_mobile_dark.png';
import Subscribe_thanks_ipad from '@/shared/images/Images/Subscribe_thanks_ipad.png';
import Subscribe_thanks_ipad_dark from '@/shared/images/Images/Subscribe_thanks_ipad_dark.png';

export const Wrapper = styled.div`
    padding: 25px 0;

    ${Screen.ipad} {
        padding: 30px 0;
    }

    ${Screen.desktop} {
        padding: 30px 0 0;
    }
`;

export const Container = styled.div`
    background-color: ${cssVars.bg_green_dark3};
    color: ${Palette.white.normal};
    padding: 42px 18px 36px;
    max-width: 1064px;
    position: relative;
    width: 100%;
    height: 220px;
    border-radius: ${radius.small};

    ${Screen.ipad} {
        padding: 57px 32px 42px;
        height: 278px;
    }
    ${Screen.desktop} {
        padding: 56px 55px 39px;
        height: 198px;
        margin: 0;
        display: flex;
        justify-content: space-between;
    }
`;

export const ContainerThankYou = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${cssVars.bg_ash_dark3};
    color: ${cssVars.fg_gray1_white};
    padding: 42px 18px 16px;
    max-width: 1064px;
    position: relative;
    width: 100%;
    height: 220px;
    border-radius: ${radius.small};

    ${Screen.ipad} {
        flex-direction: row;
        justify-content: space-between;
        padding: 57px 60px 42px 32px;
        height: 278px;
    }
    ${Screen.desktop} {
        padding: 36px 55px 39px;
        height: 198px;
        margin: 0;
        display: flex;
        justify-content: space-between;
    }
`;

export const Title = styled.h2`
    margin: 0 0 6px;
    ${Text.Mobile.heading2}

    ${Screen.ipad} {
        ${Text.Ipad.heading3}
        margin: 0 0 8px;
    }
    ${Screen.desktop} {
        ${Text.Desktop.heading2}
        margin-bottom: 27px;
    }
`;

export const Description = styled.span<{ isAlready: boolean }>`
    display: block;
    margin-bottom: 10px;
    margin-bottom: ${({ isAlready }) => (isAlready ? '10px' : '25px')};
    ${Text.Mobile.medium.regular}

    ${Screen.ipad} {
        ${Text.Ipad.large.regular}
        margin-bottom: 36px;
    }

    ${Screen.desktop} {
        ${Text.Desktop.large.regular}
        margin: 0;
        min-width: 350px;
    }
`;

export const InputWrap = styled.div`
    position: relative;

    ${Screen.desktop} {
        width: 100%;
        margin-top: 18px;
        max-width: 678px;
    }
`;

export const Input = styled.input`
    color: ${cssVars.fg_gray1_white};
    background-color: ${cssVars.bg_white_blue};
    width: 100%;
    height: 50px;
    border-radius: ${radius.large};
    border: none;
    padding: 5px 6px 5px 28px;
    ${Text.Mobile.medium.regular}

    :focus {
        outline: none;
    }

    ${Screen.ipad} {
        ${Text.Desktop.normal.regular}
    }
    ${Screen.desktop} {
        ${Text.Desktop.medium.regular}
    }
`;

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Palette.green.normal};
    width: 40px;
    height: 40px;
    border-radius: ${radius.large};
    border: none;

    position: absolute;
    right: 6px;
    top: 5px;
    cursor: pointer;

    :hover,
    :active {
        background-color: ${Palette.green.light};
    }
`;

export const ArrowIcon = styled(ArrowButton)`
    display: flex;
    align-items: center;
`;

export const Message = styled.span`
    display: flex;
    align-items: center;
    color: ${Palette.white.normal};
    margin: 6px 0 0 28px;
    ${Text.Mobile.normal.medium};

    *:first-child {
        margin-right: 5px;
    }

    ${Screen.ipad} {
        ${Text.Ipad.normal.medium}
        margin: 11px 0 0 28px;

        *:first-child {
            margin-right: 7px;
            height: 15px;
            width: 15px;
        }
    }
    ${Screen.desktop} {
        ${Text.Desktop.small.regular}
    }
`;

export const ThankYou = styled.div<{ isDark: boolean }>`
    background-image: ${({ isDark }) =>
        isDark
            ? `url(${Subscribe_thanks_mobile_dark.src})`
            : `url(${Subscribe_thanks_mobile.src})`};
    width: 141px;
    height: 109px;
    margin: 0 auto;

    ${Screen.ipad} {
        width: 240px;
        height: 185px;
        margin: 0;
        background-image: ${({ isDark }) =>
            isDark
                ? `url(${Subscribe_thanks_ipad_dark.src})`
                : `url(${Subscribe_thanks_ipad.src})`};
    }
    ${Screen.desktop} {
        width: 595px;
        height: 138px;
        margin: 0 auto;
        background-image: ${({ isDark }) =>
            isDark
                ? `url(${Subscribe_thanks_desktop_dark.src})`
                : `url(${Subscribe_thanks_desktop.src})`};
    }
`;

export const LeftPart = styled.div`
    ${Screen.ipad} {
        display: flex;
        flex-direction: column;
    }

    ${Screen.desktop} {
        padding-top: 20px;
    }
`;
