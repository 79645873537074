export const Secure = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--svg-ash-dark2)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.1822 28.674L35.1909 28.6895C35.7677 29.7277 36.8531 30.7727 37.9558 31.6159C37.9696 31.6265 37.9832 31.6375 37.9964 31.6488C38.3918 31.9877 38.8494 32.2753 39.3749 32.5088C39.4483 32.5415 39.5172 32.5834 39.5799 32.6335C39.7459 32.7663 39.9405 32.83 40.133 32.83C40.3255 32.83 40.5201 32.7663 40.686 32.6335C40.7376 32.5923 40.7934 32.5566 40.8525 32.527C41.3316 32.2875 41.8188 31.9841 42.3138 31.6132C43.4314 30.7582 44.4909 29.7932 45.0605 28.7166C45.9049 27.0213 45.898 25.1996 45.8953 24.4889C45.8951 24.4523 45.895 24.4186 45.895 24.388V20.7844C45.7846 20.7468 45.6585 20.7051 45.518 20.6598C44.9137 20.4649 44.1008 20.2207 43.2767 19.9781C42.7082 19.8107 42.1402 19.6457 41.6362 19.4994C41.41 19.4337 41.1968 19.3718 41.0022 19.3151C40.6898 19.224 40.4236 19.1459 40.2326 19.088C40.1715 19.0695 40.1155 19.0524 40.0666 19.037C40.0188 19.0522 39.964 19.0691 39.9045 19.0873C39.715 19.1452 39.4511 19.2234 39.1412 19.3145C38.9203 19.3794 38.6755 19.451 38.4149 19.5273C37.9394 19.6664 37.4116 19.8209 36.8816 19.9777C36.0616 20.2204 35.2497 20.4648 34.6409 20.6602C34.4902 20.7086 34.355 20.753 34.237 20.7929V24.321C34.237 24.3713 34.2363 24.4291 34.2355 24.4938C34.2263 25.2591 34.2055 26.992 35.1822 28.674ZM33.299 20.167C33.3433 20.034 36.2043 19.1968 38.1541 18.6263C39.1507 18.3346 39.9093 18.1127 39.932 18.09H40.2C40.2219 18.1119 40.9397 18.3204 41.8955 18.5979C43.8609 19.1687 46.833 20.0318 46.833 20.167V24.388C46.833 24.4195 46.8332 24.4553 46.8334 24.495C46.8371 25.2148 46.8474 27.2401 45.895 29.145C45.225 30.418 44.019 31.49 42.88 32.361C42.344 32.763 41.808 33.098 41.272 33.366C40.937 33.634 40.535 33.768 40.133 33.768C39.731 33.768 39.329 33.634 38.994 33.366C38.391 33.098 37.855 32.763 37.386 32.361C36.247 31.49 35.041 30.351 34.371 29.145C33.258 27.2282 33.2863 25.2544 33.2973 24.4873C33.2982 24.4232 33.299 24.3675 33.299 24.321V20.167Z"
                fill="#0DD1C5"
            />
            <path
                d="M39.396 28.0059C39.329 28.0059 39.262 28.0059 39.195 27.9389C39.128 27.9389 39.061 27.8719 39.061 27.8049L37.185 25.7949C37.118 25.7279 37.118 25.6609 37.051 25.6609C37.051 25.5939 36.984 25.5269 37.051 25.4599C37.051 25.3929 37.051 25.3259 37.118 25.2589C37.118 25.1919 37.185 25.1249 37.252 25.1249C37.319 25.0579 37.386 25.0579 37.386 24.9909C37.386 24.9239 37.52 24.9239 37.587 24.9909C37.654 24.9909 37.721 24.9909 37.788 25.0579C37.855 25.0579 37.922 25.1249 37.922 25.1919L39.463 26.7999L43.014 23.1819C43.081 23.1149 43.215 23.0479 43.349 23.0479C43.483 23.0479 43.617 23.1149 43.684 23.1819C43.751 23.2489 43.818 23.3829 43.818 23.5169C43.818 23.6509 43.751 23.7849 43.684 23.8519L39.798 27.8719C39.664 28.0059 39.53 28.0059 39.396 28.0059Z"
                fill="#0DD1C5"
            />
            <path
                d="M46.163 45.2249H19.296C18.827 45.2249 18.291 45.0239 17.956 44.6889C17.621 44.3539 17.42 43.8849 17.42 43.3489V25.3929C17.42 24.9239 17.621 24.4549 17.956 24.0529C18.291 23.7179 18.76 23.5169 19.296 23.5169H30.418C30.686 23.5169 30.954 23.7849 30.954 24.0529C30.954 24.3209 30.686 24.5889 30.418 24.5889H19.296C19.095 24.5889 18.894 24.6559 18.76 24.8569C18.626 24.9909 18.492 25.1919 18.492 25.3929V43.3489C18.492 43.5499 18.559 43.7509 18.76 43.8849C18.894 44.0189 19.095 44.1529 19.296 44.1529H46.163C46.364 44.1529 46.565 44.0859 46.699 43.8849C46.833 43.7509 46.967 43.5499 46.967 43.3489V31.7579C46.967 31.4899 47.235 31.2219 47.503 31.2219C47.771 31.2219 48.039 31.4899 48.039 31.7579V43.4159C48.039 43.8849 47.838 44.3539 47.503 44.7559C47.168 45.0239 46.699 45.2249 46.163 45.2249Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M30.485 31.021H18.023C17.755 31.021 17.487 30.753 17.487 30.485C17.487 30.217 17.755 29.949 18.023 29.949H30.485C30.753 29.949 31.021 30.217 31.021 30.485C31.021 30.753 30.753 31.021 30.485 31.021Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M29.547 37.118H21.239C20.971 37.118 20.703 36.85 20.703 36.582C20.703 36.314 20.971 36.046 21.239 36.046H29.547C29.815 36.046 30.083 36.314 30.083 36.582C30.083 36.85 29.882 37.118 29.547 37.118Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
