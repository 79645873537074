import { ReactElement, memo, useRef } from 'react';
import * as S from './RecentReviews.styles';
import { Container } from '@/common/ui/containers/Container';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { TRecentReviewsProps } from '../domain/RecentReviews';
import { RecentReviewsCard } from '@/common/ui/cards/RecentReviewCard';

export const RecentReviews = ({ reviews }: TRecentReviewsProps): ReactElement => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const { prevBtnIsActive, nextBtnIsActive, onClickHandler, onScrollHandler, DIRECTION } =
        useCarousel({ ref: scrollRef, countItems: reviews.length });

    return (
        <S.Wrapper>
            <Container>
                <S.Block data-test-id="Widget-attraction" className="container">
                    <S.Header>
                        <S.Title>Traveler Feedback and Reviews</S.Title>
                    </S.Header>
                    <S.CarouselWraper>
                        <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                            <S.InnerContainer className="carousel-container">
                                {reviews.map((review) => {
                                    return <RecentReviewsCard key={review.id} review={review} />;
                                })}
                            </S.InnerContainer>
                        </S.Gallery>
                        {prevBtnIsActive && (
                            <S.CenterLeftArrow
                                onClick={() => onClickHandler(DIRECTION.prev)}
                                disabled={false}
                            />
                        )}
                        {nextBtnIsActive && (
                            <S.CenterRightArrow
                                onClick={() => onClickHandler(DIRECTION.next)}
                                disabled={false}
                            />
                        )}
                    </S.CarouselWraper>
                </S.Block>
            </Container>
        </S.Wrapper>
    );
};

export default memo(RecentReviews);
