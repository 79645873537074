import { useEffect, useState } from 'react';
import HomePageMeta from '../app/Meta';
import { TPropsHomePage } from '../domain/HomePage.domain';
import WhyBookWithTripshock from './WhyBookWithTripshock/WhyBookWithTripshock';
import RecentlySearchedDestination from './RecentlySearchedDestination/RecentlySearchedDestination';
import CardsContainer from './CardsContainer/CardsContainer';
import URL from '@/common/app/utils/urlsUtils';
import { GiftCard } from '@/common/ui/cards/GiftCard/GiftCard';
import { TheBestThingsToDo } from './ThrBestThingsToDo';
import Subscribe from '@/shared/Subscribe/ui/Subscribe';
import Layout from '@/shared/Layout/ui/Layout';
import { useCartContext } from '@/entities/Cart/app/CartContext';
import { BlogCard } from '@/entities/Blogs/ui/BlogCard';
import { FeaturedActivities } from '@/shared/FeaturedActivities/ui/FeaturedActivities';
import { TCommonTypeActivityProps } from '@/shared/FeaturedActivities/domain/FeaturedActivities.domain';
import { getCS } from '@/common/service/storage';
import * as S from './HomePage.styles';
import { HomePageLandmarks } from '@/shared/HomePageLandmarks/ui/HomePageLandmarks';
import RecentReviews from '@/shared/RecentReviews/ui/RecentReviews';

export const HomePage = ({
    landmarks,
    blogs,
    popularDestinations,
    recentReviews,
}: TPropsHomePage) => {
    const [newActivities, setNewActivities] = useState<TCommonTypeActivityProps[]>([]);
    const { activities, cartData, runToast } = useCartContext();

    useEffect(() => {
        try {
            const json = getCS('Recently_Viewed');
            if (json && Array.isArray(json)) {
                setNewActivities(
                    json.filter(
                        (activity) =>
                            activity &&
                            typeof activity === 'object' &&
                            typeof activity.name === 'string'
                    )
                );
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        if (cartData?.cart_items?.length) {
            runToast?.('shopping_cart', { activitiesCount: cartData?.cart_items?.length });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData?.cart_items?.length]);

    return (
        <Layout isHomePage>
            <HomePageMeta />
            <S.SliderWrapper>
                {!!activities.length && (
                    <FeaturedActivities
                        activities={activities}
                        title="In Your Cart"
                        subTitle="Book early - we sell out"
                        item_list_id={'/'}
                        item_list_name={'In Your Cart'}
                    />
                )}
                {!!newActivities.length && (
                    <>
                        <FeaturedActivities
                            activities={newActivities}
                            title="Recently Viewed"
                            subTitle="Reserve your ticket"
                            item_list_id={'/'}
                            item_list_name={'Recently Viewed'}
                        />
                    </>
                )}
                <RecentlySearchedDestination />
                <WhyBookWithTripshock />
            </S.SliderWrapper>
            <TheBestThingsToDo popularDestinations={popularDestinations} />
            <Subscribe />
            <CardsContainer
                title="TripShock Blogs"
                linkName="More Blogs"
                linkHref="/blog"
                description="Travel like a PRO"
            >
                {blogs.map((blog) => {
                    return (
                        <BlogCard
                            cardLink={`/blog/${blog.slug}/`}
                            imageSrc={`${URL.image}/blog/${blog.image.id}/1000x460/${blog.image.slug}.jpg`}
                            date={blog.updated_at}
                            title={blog.title}
                            location={blog.category.name}
                            key={blog.slug}
                        />
                    );
                })}
            </CardsContainer>
            <div style={{ marginTop: '24px' }}>
                <GiftCard />
            </div>
            <HomePageLandmarks landmarks={landmarks} />
            {!!recentReviews?.length && <RecentReviews reviews={recentReviews} />}
        </Layout>
    );
};
