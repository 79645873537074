export const LocalSupport = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--svg-ash-dark2)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.1589 42.2246C38.1452 42.2403 38.131 42.2555 38.1163 42.2702C37.7506 42.6359 37.587 43.0696 37.587 43.4829V48.2719L38.1186 47.722C38.2953 47.5392 38.5387 47.4359 38.793 47.4359H47.704C48.2094 47.4359 48.6073 47.2572 48.8741 46.9523C48.8878 46.9366 48.902 46.9214 48.9167 46.9067C49.2824 46.541 49.446 46.1073 49.446 45.6939V43.5499C49.446 43.0446 49.2672 42.6467 48.9623 42.3799C48.931 42.3524 48.9015 42.323 48.8741 42.2916C48.5681 41.9419 48.1484 41.7409 47.704 41.7409H39.329C38.8236 41.7409 38.4257 41.9197 38.1589 42.2246ZM37.587 49.6215L36.85 50.3839C36.85 50.4509 36.783 50.4509 36.783 50.4509C36.783 50.4281 36.783 50.4057 36.783 50.3839C36.783 50.3784 36.783 50.3729 36.783 50.3674L36.783 50.3839M37.3122 49.7207L37.3792 49.7877L36.783 50.3839L36.716 50.4509V50.3839H36.649L37.3122 49.7207ZM36.783 50.2499L36.716 50.3169ZM36.716 50.3169L36.716 49.5154ZM36.649 50.3839V49.5231ZM36.649 49.5231V43.4829C36.649 42.8129 36.917 42.1429 37.453 41.6069C37.922 41.0709 38.592 40.8029 39.329 40.8029H47.704C48.441 40.8029 49.111 41.1379 49.58 41.6739C50.116 42.1429 50.384 42.8129 50.384 43.5499V45.6939C50.384 46.3639 50.116 47.0339 49.58 47.5699C49.111 48.1059 48.441 48.3739 47.704 48.3739H38.793L37.587 49.6215M36.783 50.4509L36.783 50.3839Z"
                fill="#0DD1C5"
            />
            <path
                d="M40.803 45.426C40.937 45.426 41.138 45.359 41.272 45.292C41.406 45.225 41.54 45.091 41.54 44.957C41.607 44.823 41.607 44.622 41.607 44.488C41.607 44.354 41.473 44.22 41.406 44.086C41.272 43.952 41.138 43.885 41.004 43.885C40.87 43.885 40.669 43.885 40.535 43.952C40.401 44.019 40.267 44.086 40.2 44.22C40.133 44.354 40.066 44.488 40.066 44.689C40.066 44.89 40.133 45.091 40.334 45.225C40.401 45.359 40.602 45.426 40.803 45.426Z"
                fill="#0DD1C5"
            />
            <path
                d="M43.885 45.426C44.019 45.426 44.22 45.359 44.354 45.292C44.488 45.225 44.622 45.091 44.622 44.957C44.689 44.823 44.689 44.622 44.689 44.488C44.689 44.354 44.555 44.22 44.488 44.086C44.421 43.952 44.22 43.885 44.086 43.885C43.952 43.885 43.751 43.885 43.617 43.952C43.483 44.019 43.349 44.086 43.282 44.22C43.215 44.354 43.148 44.488 43.148 44.689C43.148 44.89 43.215 45.091 43.416 45.225C43.483 45.359 43.684 45.426 43.885 45.426Z"
                fill="#0DD1C5"
            />
            <path
                d="M46.967 45.426C47.101 45.426 47.302 45.359 47.436 45.292C47.57 45.225 47.704 45.091 47.704 44.957C47.771 44.823 47.771 44.622 47.771 44.488C47.771 44.354 47.637 44.22 47.57 44.086C47.436 43.952 47.302 43.885 47.168 43.885C47.034 43.885 46.833 43.885 46.699 43.952C46.565 44.019 46.431 44.086 46.364 44.22C46.297 44.354 46.23 44.488 46.23 44.689C46.23 44.89 46.297 45.091 46.498 45.225C46.699 45.359 46.766 45.426 46.967 45.426Z"
                fill="#0DD1C5"
            />
            <path
                d="M21.5069 29.815C21.1719 29.815 20.9709 29.547 20.9709 29.212C21.4399 22.579 26.5989 17.42 32.6959 17.42C38.8599 17.42 43.8849 22.445 44.4209 29.145C44.4209 29.413 44.2199 29.681 43.9519 29.748C43.6839 29.748 43.4159 29.547 43.3489 29.279C42.8799 23.182 38.3239 18.559 32.6959 18.559C27.1349 18.559 22.4449 23.316 22.0429 29.346C22.0429 29.547 21.8419 29.815 21.5069 29.815Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M23.45 38.726H20.77C18.894 38.726 17.42 37.118 17.42 35.175V32.562C17.42 30.619 18.961 29.011 20.77 29.011H23.45C23.718 29.011 23.986 29.279 23.986 29.547V38.19C23.986 38.525 23.718 38.726 23.45 38.726ZM20.77 30.083C19.497 30.083 18.492 31.222 18.492 32.562V35.175C18.492 36.582 19.497 37.654 20.77 37.654H22.914V30.083H20.77Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M44.555 38.726H41.875C41.607 38.726 41.339 38.458 41.339 38.19V29.547C41.339 29.279 41.607 29.011 41.875 29.011H44.555C46.431 29.011 47.905 30.619 47.905 32.562V35.175C47.905 37.118 46.364 38.726 44.555 38.726ZM42.411 37.654H44.555C45.828 37.654 46.833 36.515 46.833 35.175V32.562C46.833 31.155 45.828 30.083 44.555 30.083H42.411V37.654Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M29.48 47.771H29.413C20.77 46.297 20.971 38.257 20.971 38.19C20.971 37.922 21.239 37.654 21.507 37.654C21.775 37.654 22.043 37.922 22.043 38.19C22.043 38.458 21.909 45.359 29.48 46.632C29.748 46.699 29.949 46.967 29.949 47.235C29.949 47.57 29.681 47.771 29.48 47.771Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M32.964 48.91H30.954C30.083 48.91 29.413 48.24 29.413 47.369V47.101C29.413 46.23 30.083 45.56 30.954 45.56H32.964C33.835 45.56 34.505 46.23 34.505 47.101V47.369C34.505 48.24 33.835 48.91 32.964 48.91ZM30.954 46.632C30.686 46.632 30.485 46.833 30.485 47.101V47.369C30.485 47.637 30.686 47.838 30.954 47.838H32.964C33.232 47.838 33.433 47.637 33.433 47.369V47.101C33.433 46.833 33.232 46.632 32.964 46.632H30.954Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
