import { TDestinationItem } from '@/common/service/api/Destination/Destination.domain';
import { TPublicBlog } from '@/entities/Blogs/domain/Blogs.domain';
import { RecentReviewsCardItem } from '@/entities/Reviews/domain/Reviews.domain';
import { TLandmarkWidget } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';

export type TPropsHomePage = {
    blogs: TPublicBlog[];
    landmarks: TLandmarkWidget[];
    popularDestinations: TDestinationItem[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recentReviews: RecentReviewsCardItem[];
};

export enum MetaValues {
    title = `TripShock! - Book The Best Activities, Tours & Things To Do`,
    description = `The USA's #1 booking website for activities, tours, and things to do. Browse reviews, deals, photos and more from over 1,000+ experiences. Low price guarantee on every booking.`,
    imageDescription = `TripShock! - Book The Best Activities, Tours & Things To Do`,
    href = `https://www.tripshock.com/`,
}
