import { memo } from 'react';

import { buildRootUrl } from '@/common/app/utils/urlsUtils';
import { MetaValues } from '../domain/HomePage.domain';
import { CommonMeta } from '@/common/ui/meta/CommonMeta';

const HomePageMeta = () => {
    const { imageDescription, description, href, title } = MetaValues;
    return (
        <CommonMeta
            title={title}
            description={description}
            imageSrc={''}
            slug={buildRootUrl()}
            ogTitle={imageDescription}
            ogUrl={href}
            canonical={href}
        />
    );
};

export default memo(HomePageMeta);
