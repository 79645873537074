import styled, { css } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';

export const Wrapper = styled.div`
    background-color: ${cssVars.bg_white_blue};
    width: 100%;
    padding: 22px 0 0;

    ${Screen.ipad} {
        padding: 27px 0 0;
    }

    ${Screen.desktop} {
        padding: 60px 0 0;
    }
`;

export const CardsContainer = styled.div`
    margin: 0 18px;
    max-width: 1124px;

    ${Screen.ipad} {
        margin: 0 30px;
    }
    ${Screen.desktop} {
        margin: 0 auto;
        padding: 0 30px;
    }
`;

export const Header = styled.div<{ isLong?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 17px;
    ${({ isLong }) =>
        isLong &&
        css`
            flex-direction: column;
        `}

    ${Screen.ipad} {
        margin-bottom: 21px;
    }

    ${Screen.desktop} {
        flex-direction: row;
        margin-bottom: 24px;
    }
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Mobile.heading2};

    ${Screen.ipad} {
        ${Text.Ipad.heading3}
    }
    ${Screen.desktop} {
        ${Text.Desktop.heading2}
    }
`;

export const Link = styled.a<{ isLong?: boolean }>`
    color: ${Palette.green.normal};
    ${Text.Full.large.semibold}
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 50%;

    ${({ isLong }) =>
        isLong &&
        css`
            width: 100%;
            max-width: 100%;
        `}

    ${Screen.desktop} {
        text-decoration: underline;
        width: auto;
    }
    :active,
    :hover {
        color: ${Palette.green.light};
    }
    transition: 0.5s;
`;

export const Description = styled.span`
    color: ${cssVars.fg_gray2_gray3};
    width: 100%;
    ${Text.Full.large.medium}
    margin-top: 4pxpx;

    ${Screen.ipad} {
        margin-top: 6px;
    }
`;

export const Content = styled.div`
    display: grid;
    grid-template: 1fr / none;
    grid-gap: 12px;
    *:nth-of-type(n + 3) {
        display: none;
    }
    ${Screen.ipad} {
        grid-gap: 24px;
    }
    ${Screen.desktop} {
        *:nth-of-type(n + 3) {
            display: block;
        }
        grid-template: 1fr / 1fr 1fr;
    }
`;

export const Arrow = styled(DropdownArrow)`
    height: 18px;
    width: 18px;
    transform: rotate(-90deg);
    fill: ${Palette.green.normal};
    margin: 0 4px;
    ${Screen.ipad} {
        height: 20px;
        width: 20px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
