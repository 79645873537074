import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';
import styled from 'styled-components';
import { radius } from '@/styles/Themes/Metrics';
import Text from '@/styles/Themes/Text';
import { Palette } from '@/styles/Themes/Palette';

export const RecentReviewCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 20px 24px;
    margin: 10px 0 24px;
    width: 300px;
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_hover};
    background: ${cssVars.bg_white_dark3};

    ${Screen.ipad} {
        width: 340px;
    }

    ${Screen.desktop} {
        padding: 24px;
    }
`;

export const ContentContainer = styled.div`
    margin-bottom: 24px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    svg {
        color: ${cssVars.bg_ash_blueDark};
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    ${Text.Full.large.medium};
    color: ${Palette.green.normal};
    margin-bottom: 3px;
`;

export const Description = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.medium.regular};
`;

export const RatingContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin: 6px 0 10px 0;
`;

export const Rate = styled.div`
    margin-left: 10px;
    color: ${cssVars.fg_gray2_white};
    ${Text.Full.medium.regular};
`;

export const Author = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold};
`;

export const Time = styled.div`
    margin-left: 16px;
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular};
`;

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ReviewOnTitle = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.semibold};
`;

export const ReviewLink = styled.a`
    color: ${cssVars.fg_gray2_white};
    ${Text.Full.medium.regular};
    text-decoration: underline;
`;
