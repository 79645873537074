export const LowPrices = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--svg-ash-dark2)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.1918 42.4649L48.1882 42.4448C47.9569 41.1728 47.3185 40.002 46.3708 39.0543C45.423 38.1065 44.2522 37.4681 42.9802 37.2369C41.6711 36.9988 40.3144 37.1203 39.1519 37.5827C37.9201 38.1129 36.9276 38.9288 36.1599 40.0455C35.443 41.1855 35.041 42.4163 35.041 43.751C35.041 44.6363 35.2189 45.5049 35.5577 46.2389L35.5631 46.2506L35.5682 46.2625C35.9244 47.0937 36.3939 47.7973 37.0443 48.4477C37.6936 49.097 38.3958 49.5659 39.2251 49.9219C39.9064 50.205 40.786 50.384 41.674 50.384C42.9515 50.384 44.2539 49.9761 45.3941 49.2559C46.4807 48.5697 47.3056 47.5255 47.8469 46.2625C48.3723 45.0364 48.4872 43.7644 48.1963 42.4849L48.1918 42.4649ZM38.86 50.786C37.922 50.384 37.118 49.848 36.381 49.111C35.644 48.374 35.108 47.57 34.706 46.632C34.304 45.761 34.103 44.756 34.103 43.751C34.103 42.21 34.572 40.803 35.376 39.53C36.247 38.257 37.386 37.319 38.793 36.716C40.133 36.18 41.674 36.046 43.148 36.314C44.622 36.582 45.962 37.319 47.034 38.391C48.106 39.463 48.843 40.803 49.111 42.277C49.446 43.751 49.312 45.225 48.709 46.632C48.106 48.039 47.168 49.245 45.895 50.049C44.622 50.853 43.148 51.322 41.674 51.322C40.669 51.322 39.664 51.121 38.86 50.786Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.0092 39.6641H41.4062V40.3354C40.9414 40.3535 40.4839 40.5586 40.1528 40.8897C39.9495 41.093 39.8095 41.2998 39.7375 41.5105C39.5971 41.7275 39.5972 42.0084 39.5972 42.2025L39.5972 42.2101C39.5972 42.6947 39.7536 43.0982 40.0697 43.4055C40.3746 43.702 40.8225 43.9037 41.4062 44.0085V46.3477C41.1228 46.2785 40.9122 46.1561 40.7624 45.9777C40.5981 45.7821 40.5006 45.5121 40.4679 45.1521L40.4624 45.0911H39.4594L39.4633 45.1618C39.4973 45.775 39.6683 46.2449 39.993 46.5784C40.3069 46.9007 40.7561 47.0872 41.3392 47.1607V47.8381H41.9422V47.168C42.1806 47.1672 42.383 47.1572 42.6424 47.0275L42.651 47.0217L42.6538 47.0199C42.8532 46.887 43.058 46.7504 43.2627 46.5457L43.2666 46.541C43.6091 46.13 43.8182 45.6463 43.8182 45.1581C43.8182 44.7412 43.6792 44.3243 43.4015 43.9773C43.1249 43.6315 42.6526 43.4284 41.9438 43.2937L42.0072 41.1405C42.1859 41.1537 42.3867 41.2222 42.4894 41.3763L42.4931 41.3818L42.4978 41.3865C42.5928 41.4815 42.654 41.5743 42.692 41.6731C42.7301 41.7721 42.7462 41.8807 42.7462 42.0091V42.0761H43.7607L43.7506 42.0003C43.6823 41.4881 43.5103 41.0864 43.2108 40.8046C42.9229 40.5336 42.5247 40.3805 42.0092 40.3388V39.6641ZM40.5352 42.1431C40.5352 41.8547 40.6149 41.6374 40.7607 41.4762C40.8936 41.3294 41.0864 41.2235 41.3392 41.1564V43.1229C41.1518 43.0626 40.9448 42.994 40.7836 42.8327C40.7566 42.8058 40.7267 42.762 40.6938 42.7045C40.6614 42.6478 40.6288 42.5826 40.5951 42.5152C40.5362 42.3973 40.5352 42.2788 40.5352 42.1431ZM41.9422 46.3552V44.2406C42.1853 44.3109 42.458 44.4315 42.6271 44.5979C42.7515 44.7863 42.8132 44.9733 42.8132 45.2251C42.8132 45.5495 42.7321 45.818 42.5766 46.0124C42.4341 46.1905 42.2245 46.3122 41.9422 46.3552Z"
                fill="#0DD1C5"
            />
            <path
                d="M31.289 43.818H19.564C19.028 43.818 18.492 43.617 18.09 43.215C17.621 42.813 17.42 42.277 17.42 41.741V21.775C17.42 21.306 17.621 20.904 17.889 20.569C18.224 20.301 18.693 20.1 19.095 20.1H47.972C48.173 20.1 48.441 20.167 48.642 20.234C48.843 20.301 49.044 20.435 49.178 20.569C49.379 20.77 49.446 20.971 49.58 21.172C49.647 21.373 49.714 21.574 49.714 21.842V35.979C49.714 36.247 49.446 36.515 49.178 36.515C48.91 36.515 48.642 36.247 48.642 35.979V21.775C48.642 21.708 48.642 21.641 48.575 21.507C48.575 21.44 48.508 21.373 48.441 21.306C48.374 21.239 48.307 21.172 48.24 21.172C48.173 21.172 48.106 21.105 47.972 21.105H19.095C18.961 21.172 18.76 21.239 18.693 21.373C18.559 21.44 18.492 21.641 18.492 21.775V41.674C18.492 41.942 18.626 42.21 18.827 42.411C19.028 42.612 19.296 42.746 19.564 42.746H31.356C31.624 42.746 31.892 43.014 31.892 43.282C31.892 43.55 31.624 43.818 31.289 43.818Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.4651 36.4479C26.1971 36.4479 25.9291 36.1799 25.9291 35.9119V26.2639C25.9291 25.9959 26.1971 25.7279 26.4651 25.7279C26.7331 25.7279 27.0011 25.9959 27.0011 26.2639V35.9119C27.0011 36.2469 26.8001 36.4479 26.4651 36.4479Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.4651 36.4479C26.3311 36.4479 26.1971 36.3809 26.1301 36.3139L22.5791 33.2319C22.3781 33.0309 22.3111 32.6959 22.5121 32.4949C22.7131 32.2939 23.0481 32.2269 23.2491 32.4279L26.4651 35.1749L29.6811 32.4279C29.8821 32.2269 30.2171 32.2939 30.4181 32.4949C30.6191 32.6959 30.5521 33.0309 30.3511 33.2319L26.8001 36.3139C26.7331 36.4479 26.5991 36.4479 26.4651 36.4479Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M49.178 35.0409H44.354C43.55 35.0409 42.813 34.7059 42.21 34.1699C41.674 33.6339 41.339 32.8299 41.339 32.0929V31.4229C41.339 30.6189 41.674 29.8819 42.21 29.2789C42.746 28.7429 43.55 28.4079 44.354 28.4079H49.178C49.446 28.4079 49.714 28.6759 49.714 28.9439C49.714 29.2119 49.446 29.4799 49.178 29.4799H44.354C43.818 29.4799 43.349 29.6809 43.014 30.0159C42.679 30.3509 42.478 30.8869 42.478 31.3559V32.0259C42.478 32.5619 42.679 33.0309 43.014 33.3659C43.349 33.7009 43.885 33.9019 44.354 33.9019H49.178C49.446 33.9019 49.714 34.1699 49.714 34.4379C49.714 34.7059 49.446 35.0409 49.178 35.0409Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M44.9571 32.6291C45.4261 32.6291 45.8281 32.2271 45.8281 31.7581C45.8281 31.2891 45.4261 30.8871 44.9571 30.8871C44.4881 30.8871 44.0861 31.2891 44.0861 31.7581C44.0861 32.2271 44.4881 32.6291 44.9571 32.6291Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
