import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import ErrorMessage from '@/shared/images/icons/ErrorMessage';
import { borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';

export const ErrorText = styled.div`
    ${Text.Mobile.normal.semibold};
    display: flex;
    align-items: center;
    position: relative;
    background: ${cssVars.bg_lightBg_darkBg2};
    border-radius: 10px;
    border: ${borders.small} ${cssVars.bg_gray4_dark2};
    color: ${Palette.ash.normal};
    width: 80%;
    height: 28px;
    top: 7px;
    padding: 8px 15px;
    box-shadow: ${cssVars.shadow_card_default};

    ${Screen.ipad} {
        ${Text.Ipad.normal.semibold};
        height: 32px;
        width: 45%;
    }

    ${Screen.desktop} {
        height: 35px;
        width: 60%;
    }

    &:before {
        content: '';
        height: 8px;
        width: 8px;
        border-top: ${borders.small} ${cssVars.bg_gray4_dark2};
        border-right: ${borders.small} ${cssVars.bg_gray4_dark2};
        background: ${cssVars.bg_lightBg_darkBg2};
        border-left: none;
        border-bottom: none;
        transform: rotate(-45deg);
        position: absolute;
        top: -6px;
        left: 30px;
    }

    > svg {
        > circle {
            fill: ${cssVars.bg_blue_circle};
        }
        > path {
            fill: ${cssVars.fg_blue_alert};
        }
    }
`;

export const ErrorIcon = styled(ErrorMessage)`
    margin: 0 10px;
`;
