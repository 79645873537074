export const Warning = (props: { className?: string }) => {
    return (
        <svg
            className={`Warning ${props.className}`}
            width="14"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99609 1.75C5.07943 1.75 2.74609 4.08333 2.74609 7C2.74609 9.91667 5.07943 12.25 7.99609 12.25C10.9128 12.25 13.2461 9.91667 13.2461 7C13.2461 4.08333 10.8641 1.75 7.99609 1.75ZM7.99609 11.4722C5.51693 11.4722 3.47526 9.47917 3.47526 6.95139C3.47526 4.42361 5.46832 2.43056 7.99609 2.43056C10.5239 2.43056 12.4683 4.47222 12.4683 7C12.4683 9.52778 10.4753 11.4722 7.99609 11.4722Z"
                fill="white"
            />
            <path
                d="M7.93763 10.2084C8.17096 10.2084 8.4043 10.0334 8.4043 9.80004C8.4043 9.50837 8.17096 9.33337 7.93763 9.33337C7.7043 9.33337 7.5293 9.50837 7.5293 9.74171C7.5293 9.97504 7.7043 10.2084 7.93763 10.2084Z"
                fill="white"
            />
            <path d="M7.4126 4.08337H8.4626L8.2876 8.63337H7.5876L7.4126 4.08337Z" fill="white" />
        </svg>
    );
};
