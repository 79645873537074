import styled from 'styled-components';
import { radius } from 'src/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';

export const Item = styled.div`
    background: ${cssVars.bg_white_dark3};
    box-shadow: ${cssVars.shadow_card_default};
    border-radius: ${radius.small};
    overflow: hidden;
    padding: 20px;

    ${Screen.ipad} {
        padding: 30px;
    }

    ${Screen.desktop} {
        padding: 27px 33px;
    }
`;
