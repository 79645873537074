export const QuotesIcon = () => {
    return (
        <svg
            width="25"
            height="17"
            viewBox="0 0 25 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M-8.6604e-07 5.08881C-7.38932e-07 3.63487 0.484495 2.42325 1.45349 1.45395C2.42248 0.48465 3.68217 4.36847e-08 5.23256 1.79224e-07C7.13178 3.45259e-07 8.70155 0.671053 9.94186 2.01316C11.1434 3.39254 11.7442 5.03289 11.7442 6.93421C11.7442 9.69298 10.6783 12.0603 8.54651 14.0362C6.41473 16.0121 3.83721 17 0.813953 17L0.697674 16.2171C2.55814 16.2171 4.26357 15.5833 5.81395 14.3158C7.36434 13.0482 8.13953 11.5943 8.13953 9.95395C8.13953 9.58114 8.10077 9.30153 8.02326 9.11513C7.90698 8.92872 7.75194 8.83552 7.55814 8.83552C7.28682 8.83552 6.87984 8.94737 6.33721 9.17105C5.75581 9.43202 5.27132 9.5625 4.88372 9.5625C3.60465 9.5625 2.48062 9.13377 1.51163 8.27631C0.503875 7.45614 -9.80111e-07 6.39364 -8.6604e-07 5.08881ZM13.2558 5.08882C13.2558 3.63487 13.7403 2.42325 14.7093 1.45395C15.6783 0.484651 16.938 1.20254e-06 18.4884 1.33808e-06C20.3876 1.50412e-06 21.9574 0.671055 23.1977 2.01316C24.3992 3.39255 25 5.0329 25 6.93421C25 9.69298 23.9341 12.0603 21.8023 14.0362C19.6705 16.0121 17.093 17 14.0698 17L13.9535 16.2171C15.814 16.2171 17.5194 15.5833 19.0698 14.3158C20.6202 13.0482 21.3953 11.5943 21.3953 9.95395C21.3953 9.58114 21.3566 9.30153 21.2791 9.11513C21.1628 8.92873 21.0078 8.83553 20.814 8.83553C20.5426 8.83553 20.1357 8.94737 19.593 9.17105C19.0116 9.43202 18.5271 9.5625 18.1395 9.5625C16.8605 9.5625 15.7364 9.13377 14.7674 8.27632C13.7597 7.45614 13.2558 6.39364 13.2558 5.08882Z"
                fill="currentColor"
            />
        </svg>
    );
};
