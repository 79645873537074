export const Verified = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--svg-ash-dark2)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.3196 42.8812C39.3235 42.9089 39.3263 42.9367 39.3277 42.9646L39.4345 44.9942L41.287 44.8913C41.2967 44.8908 41.3064 44.8904 41.3161 44.8901C41.3168 44.8898 41.3176 44.8895 41.3183 44.8891C41.3254 44.8859 41.3342 44.8818 41.346 44.876C41.3529 44.8726 41.36 44.8691 41.368 44.8651C41.3731 44.8611 41.3808 44.8548 41.3913 44.8456C41.4282 44.8133 41.4697 44.7726 41.534 44.7083C42.3732 43.8015 44.418 41.5405 46.3083 39.4171C47.2577 38.3505 48.1651 37.3221 48.8591 36.5199C49.2065 36.1183 49.4974 35.7769 49.7123 35.5173C49.82 35.3871 49.9053 35.2815 49.9677 35.2013C49.9839 35.1805 49.9973 35.163 50.0082 35.1486C50.0093 35.1392 50.0103 35.1284 50.0113 35.1162C50.0199 35.0107 50.02 34.86 50.0088 34.6688C49.9868 34.2953 49.9273 33.8548 49.8639 33.472C49.836 33.3486 49.7541 33.2284 49.6872 33.1783C49.5655 33.087 49.4248 33.0309 49.245 33.0309C49.2349 33.0309 49.2248 33.0307 49.2148 33.0304L47.2261 32.9662L39.5136 42.2744C39.3382 42.4848 39.2924 42.6906 39.3196 42.8812ZM47.168 32.0259L49.245 32.0929C49.647 32.0929 49.982 32.2269 50.25 32.4279C50.518 32.6289 50.719 32.9639 50.786 33.2989C50.92 34.1029 51.054 35.2419 50.853 35.5769C50.451 36.2469 43.885 43.5499 42.21 45.3589C42.076 45.4929 41.942 45.6269 41.808 45.6939C41.674 45.7609 41.54 45.8279 41.339 45.8279L38.927 45.9619C38.86 45.9619 38.726 45.8949 38.659 45.8279C38.592 45.7609 38.525 45.6939 38.525 45.5599L38.391 43.0139C38.324 42.5449 38.458 42.0759 38.793 41.6739L46.565 32.2939C46.632 32.2269 46.699 32.1599 46.833 32.0929C46.9 32.0259 47.034 32.0259 47.168 32.0259Z"
                fill="#0DD1C5"
            />
            <path
                d="M45.0909 34.1029L44.5549 34.7059L48.5749 38.3239L49.1109 37.7209L45.0909 34.1029Z"
                fill="#0DD1C5"
            />
            <path
                d="M36.582 48.6419H22.646C22.177 48.6419 21.641 48.4409 21.306 48.1059C20.971 47.7709 20.77 47.3019 20.77 46.7659V18.6929C20.77 18.1569 20.971 17.6879 21.306 17.3529C21.641 17.0179 22.177 16.8169 22.646 16.8169H35.309C35.443 16.8169 35.644 16.8839 35.711 17.0179L42.813 25.1919C42.88 25.2589 42.947 25.3929 42.947 25.5269V31.6239C42.947 31.8919 42.679 32.1599 42.411 32.1599C42.143 32.1599 41.875 31.8919 41.875 31.6239V25.7279L35.108 17.8219H22.713C22.512 17.8219 22.311 17.8889 22.11 18.0899C21.976 18.2239 21.842 18.4249 21.842 18.6929V46.8329C21.842 47.0339 21.909 47.2349 22.11 47.4359C22.244 47.5699 22.445 47.7039 22.713 47.7039H36.649C36.917 47.7039 37.185 47.9719 37.185 48.2399C37.185 48.5079 36.917 48.6419 36.582 48.6419Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M35.845 31.0879H28.944C28.676 31.0879 28.408 30.8199 28.408 30.5519C28.408 30.2839 28.676 30.0159 28.944 30.0159H35.845C36.113 30.0159 36.381 30.2839 36.381 30.5519C36.381 30.8199 36.113 31.0879 35.845 31.0879Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.063 31.0879H25.527C25.259 31.0879 24.991 30.8199 24.991 30.5519C24.991 30.2839 25.259 30.0159 25.527 30.0159H26.063C26.331 30.0159 26.599 30.2839 26.599 30.5519C26.599 30.8199 26.331 31.0879 26.063 31.0879Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.13 35.4429H25.661C25.393 35.4429 25.125 35.1749 25.125 34.9069C25.125 34.6389 25.393 34.3709 25.661 34.3709H26.197C26.465 34.3709 26.733 34.6389 26.733 34.9069C26.733 35.1749 26.465 35.4429 26.13 35.4429Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.264 39.731H25.728C25.46 39.731 25.192 39.463 25.192 39.195C25.192 38.927 25.46 38.659 25.728 38.659H26.264C26.532 38.659 26.8 38.927 26.8 39.195C26.8 39.463 26.599 39.731 26.264 39.731Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M35.845 35.4429H28.944C28.676 35.4429 28.408 35.1749 28.408 34.9069C28.408 34.6389 28.676 34.3709 28.944 34.3709H35.845C36.113 34.3709 36.381 34.6389 36.381 34.9069C36.381 35.1749 36.113 35.4429 35.845 35.4429Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M35.845 39.731H28.944C28.676 39.731 28.408 39.463 28.408 39.195C28.408 38.927 28.676 38.659 28.944 38.659H35.845C36.113 38.659 36.381 38.927 36.381 39.195C36.381 39.463 36.113 39.731 35.845 39.731Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M41.808 25.996H36.716C35.711 25.996 34.84 25.125 34.84 24.12V17.956C34.84 17.688 35.108 17.42 35.376 17.42C35.644 17.42 35.912 17.688 35.912 17.956V24.12C35.912 24.589 36.247 24.924 36.716 24.924H41.808C42.076 24.924 42.344 25.192 42.344 25.46C42.344 25.728 42.143 25.996 41.808 25.996Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
